export default function videoOverlay() {
  imagesLoaded( $body, function() {

    var $videoContainer = $('.content').find('.video-container');
    function overlayInit(){
      $videoContainer.each(function(index, el) {
        var $videoOverlay = $(this).find('.video-overlay');
        var videoTop = $(this).offset().top;
        var videoBottom = videoTop + $(this).outerHeight();
        var windowHeight = $window.height();
        var coefficient = 0.7;

        $window.on('scroll', function(event) {
          var scroll = $window.scrollTop();
          if(scroll>videoTop-windowHeight && scroll<videoBottom){
            $videoOverlay.show();
            var opacity;
            var average = videoTop-(windowHeight/2-(videoBottom-videoTop)/2);
            if(scroll<=average){
              opacity = (scroll-videoTop+windowHeight)/(average-videoTop+windowHeight);
            }
            else{
              opacity = (videoBottom-scroll)/(average-videoTop+windowHeight);
            }
            $videoOverlay.css('opacity', coefficient*opacity);
          }else{
            $videoOverlay.hide();
          }

        });

        $body.on('click tap', function(event) {
          $videoOverlay.hide();
          $window.unbind('scroll');
        });
        $videoOverlay.on('click tap', function(event) {
          $videoOverlay.hide();
          $window.unbind('scroll');
        });

      });

    }

    overlayInit();

    $window.on('resize', function(event) {
      overlayInit();
    });
  });
}
