export default function flexslider() {
    //opinion flexslider
    var opinionSlider = $('.flexslider.opinion-widget');
    if(opinionSlider.length > 0){
        var flexRegExp= new RegExp('columns_slider__' + '(\\d+)');
        var css_breakpoint_holder = $('#css-breakpoint-holder');
        if (css_breakpoint_holder.length == 0) $('<div id="css-breakpoint-holder"></div>').appendTo($body);
        var flexBreak = $('#css-breakpoint-holder').css('font-family');
        if(parseInt(flexRegExp.exec(flexBreak)[1]) == 1){
            opinionSlider.flexslider();
        }
    }


    // post sliders

    var $window = $(window),
        $postSlider = $('#post-slider'),
        postSliderSlides = $postSlider.find('.slides'),
        postSliderHeight,
        $latestPostSlider = $('#latest-posts-slider'),
        loadMore,
        cropText,
        $singlePostGallery = $('.single-post-gallery');
    var $sliderPopup = $('.slider-popup');
    var sliderPopupLength = $sliderPopup.length;


    loadMore = function (slider) {
        var loader = slider.container.data('ajax-posts-loader');

        if (loader) {
            loader.checkCount()
                .load();
        }
    };

    cropText = function (slider) {
        $(slider.newSlides.find('h3').find('.hidden')).textCropper();
    };

    function getGridSize() {
        return (window.innerWidth < 768) ? 1 :
            (window.innerWidth < 1024) ? 2 : 3;
    }

    function slidersInit(){
        $latestPostSlider.flexslider({
            animation: "slide",
            animationLoop: false,
            animationSpeed: 3000,
            slideshow: false,
            controlNav: false,
            itemWidth: 318,
            itemMargin: 40,
            minItems: getGridSize(),
            maxItems: getGridSize(),
            before: loadMore,
            start: cropText,
            added: cropText
        });

        if($postSlider.length > 0){
            $postSlider.flexslider({
                animation: "slide",
                animationLoop: false,
                animationSpeed: 3000,
                slideshow: false,
                controlNav: false,
                itemWidth: 330,
                itemMargin: 50,
                minItems: getGridSize(),
                maxItems: getGridSize(),
                start: function(){
                    postSliderHeight = postSliderSlides.outerHeight();
                    postSliderSlides.css('height', postSliderHeight + 'px');
                },
                before: loadMore
            });
        }
        if($singlePostGallery.length > 0){
            $singlePostGallery.each(function(index, el) {
                var thisSlider = $(this);
                thisSlider.flexslider({
                    animation: "fade",
                    animationLoop: false,
                    animationSpeed: 1000,
                    slideshowSpeed: 10000,
                    start: function(){
                        var thisNav = thisSlider.find('.flex-control-nav');
                        var thisZoom = thisSlider.find('.full-size');
                        var thisImg= thisSlider.find('img');
                        thisNav.css('top', thisImg.outerHeight() - 38 + 'px');
                        thisZoom.css('top', thisImg.outerHeight()/2 - 28 + 'px');
                        $window.on('resize', function() {
                            thisNav.css('top', thisImg.outerHeight() - 38 + 'px');
                            thisZoom.css('top', thisImg.outerHeight()/2 - 28 + 'px');
                        });
                    }
                });
            });
        }
    }
    $window.load(function() {
        slidersInit();
    });


    $window.on('resize orientationchange', function() {
        var gridSize = getGridSize();

        $latestPostSlider.data("flexslider").vars.minItems = gridSize;
        $latestPostSlider.data("flexslider").vars.maxItems = gridSize;

        if($postSlider.length > 0){
            $postSlider.data("flexslider").vars.minItems = gridSize;
            $postSlider.data("flexslider").vars.maxItems = gridSize;

            postSliderSlides.css('height', 'auto');
            postSliderHeight = postSliderSlides.outerHeight();
            postSliderSlides.css('height', postSliderHeight + 'px');

        }
    });


    $('.full-size').on('click', function(event) {
        event.preventDefault();
        var number = parseInt($(this).parent().data('number'));
        for(var i = 0; i< sliderPopupLength; i++){
            var sliderPopupNumber = parseInt($sliderPopup.eq(i).data('number'));
            if(sliderPopupNumber==number){
                $sliderPopup.eq(i).addClass('slider-popup-open');
            }
        }
    });
    $('.slider-popup-close').on('click', function(event) {
        event.preventDefault();
        $sliderPopup.removeClass('slider-popup-open');
    });
    $('.slider-popup').on('click', function(event) {
        event.preventDefault();
        $sliderPopup.removeClass('slider-popup-open');
    });
    $singlePostGallery.on('click', function(event) {
        event.stopPropagation();
    });
}
