import $ from 'jquery';

import ajaxPostsLoader from './modules/ajaxPostsLoader';
import processArray from './modules/processArray';
import textCropper from './modules/textCropper';

import flexslider from './partials/flexslider';
import newsletter from './partials/newsletter';
import videoOverlay from './partials/videoOverlay';

$(function () {

    window.$ = $;
    window.$document = $(document);
    window.$window = $(window);
    window.$head = $('head');
    window.$body = $('body');
    window.$header = $('#header');
    window.$footer = $('#footer');

    // init modules
    ajaxPostsLoader();
    textCropper();

    // run partials
    flexslider();
    newsletter();
    videoOverlay();

    var animOnScroll,
        grid = document.getElementById('grid'),
        timer,
        //thema widget text crop
        $themeWidgetText = $('.theme-widget').find('.hidden').not('p'),
        $themeWidgetTitle = $('.theme-widget').find('p.hidden'),
        //one small post crop
        $smallPostText = $('.with-most-read-posts').find('.post-small-size').find('h2').find('a');

    $themeWidgetTitle.textCropper(12);
    $themeWidgetText.textCropper();
    $smallPostText.textCropper();
    $window.on('resize orientationchange', function () {

        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            $themeWidgetTitle.textCropper(12);
            $themeWidgetText.textCropper();
            $smallPostText.textCropper();
        }, 250);
    });

    //expanded menu
    $('.menu-item-has-children').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        $body.removeClass('header-search-open').toggleClass('expanded-menu-open');
    });
    $('.menu-item-has-children div').on('click', function (event) {
        event.stopPropagation();
    });

    //push menu
    $('.menu-icon').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        $body.removeClass('header-search-open').toggleClass('push-menu-open');
    });

    //header search
    $('.header-search').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        $body.removeClass('expanded-menu-open push-menu-open').toggleClass('header-search-open');
    });
    $('.search-button').on('click', function (event) {
        event.preventDefault();
        event.stopPropagation();
        $body.removeClass('expanded-menu-open push-menu-open').toggleClass('header-search-open');
    });
    $('.header-search-form').on('click', function (event) {
        event.stopPropagation();
    });

    $body.on('click', function () {
        $body.removeClass('expanded-menu-open push-menu-open header-search-open');
    });

    //button up
    $('.button-up').on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, 1000);
    });

    $('.socials-open').on('click', function () {
        $(this).toggleClass('opened');
    });

    //open icon click
    $('.open-icon').on('click', function (event) {
        event.preventDefault();
        $(this).parent().toggleClass('open');
    });

    if (grid) {
        if ($(grid).hasClass('post-list--litt-smarter')) {
            var lis = $(grid).find('> li');
            lis = processArray(lis);
            $(grid).html(lis)
        }

        animOnScroll = new window.AnimOnScroll(grid, {
            minDuration: 0.4,
            maxDuration: 0.7,
            viewportFactor: 0.2
        });
    }

    $('[data-ajax-posts-loader]').ajaxPostsLoader()
        .on('postsadd', function (event, $html) {
            var items,
                slider = $(this).closest('.flexslider').data('flexslider');

            if (this === grid) {
                items = [];
                $html.filter('li').each(function (i, el) {
                    animOnScroll.items[animOnScroll.items.length] = items[items.length] = el;
                });
                animOnScroll.itemsCount += items.length;
                window.imagesLoaded(this, function () {
                    animOnScroll.msnry
                        .appended(items);
                    animOnScroll._scrollPage();
                });
            }

            if (slider) {
                $html.filter('li').each(function () {
                    slider.addSlide(this);
                });
            }
        });
});