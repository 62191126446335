function is_empty(el) {

    el.removeClass('error error-empty');

    if ( !el.val() ) {
        el.addClass('error error-empty');
        return true;
    }

    return false;

}

function is_correct_email(el) {

    var regex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

    el.removeClass('error error-email');

    if ( !regex.test(el.val()) ) {
        el.addClass('error error-email');
        return false;
    }

    return true;
}

function terms_accepted(el) {
    var isTermsAccepted = el.find('.newsletter-checkbox__input')[0].checked;

    el[isTermsAccepted ? 'removeClass' : 'addClass']('newsletter-checkbox--has-error');

    return isTermsAccepted;
}

export default function newsletter() {
    $('.newsletter-widget').find('form').on('submit', function () {

        var newsletter_form = $(this).removeClass('error');
        var serialized_form = newsletter_form.serialize();
        var email_input = newsletter_form.find('input[type=email]');

        if ( is_empty(email_input) ) return false;
        if ( !terms_accepted(newsletter_form.find('.newsletter-checkbox')) ) return false;

        if ( is_correct_email(email_input) ) {

            $.ajax({
                type: 'POST',
                url: newsletter_form.attr('action').replace('/post?', '/post-json?').concat('&c=?'),
                data: serialized_form,
                dataType: 'jsonp',
                beforeSend: function () {
                    newsletter_form.addClass('loading');
                    email_input.attr('readonly', true);
                },
                success: function (response) {

                    if (response.result === 'success') {
                        newsletter_form.removeClass('loading').addClass('success');
                        email_input.val('');
                        newsletter_form.find('.newsletter-checkbox').css('display', 'none');

                        setTimeout(function () {
                            newsletter_form.removeClass('success');
                            email_input.attr('readonly', false);
                            newsletter_form.find('.newsletter-checkbox').css('display', 'flex');
                        }, 5000);
                    } else {
                        newsletter_form.addClass('error');
                        setTimeout(function () {
                            newsletter_form.removeClass('error');
                        }, 500);
                    }
                },
                error: function () {
                    newsletter_form.removeClass('loading');
                    email_input.attr('readonly', false);
                }
            });

        } else {
            newsletter_form.addClass('error');
            setTimeout(function () {
                newsletter_form.removeClass('error');
            }, 500);
        }

        return false;

    });

    $('.newsletter-checkbox__label').on('click', function () {
        $(this).toggleClass('newsletter-checkbox__label--checked');
    })
}
