export default function processArray(array, offset) {
    offset = offset || 0;
    var newArr = [];

    for (var idx = offset; idx < array.length + offset; idx++) {
        var elementIdx = idx - offset;
        var el = array[elementIdx];
        var adaptedIdx = idx + 1;
        var n = Math.floor(adaptedIdx / 9) + 1;
        var is0 = idx === 0;
        var is4th = (9 * n - 5) === adaptedIdx && !is0;
        var is8th = (9 * n - 1) === adaptedIdx && !is0;

        if (is4th || is8th) {
            var nexEl = array[elementIdx + 1];
            var elHtml = [$(el).html(), $(nexEl).html()];
            newArr.push($('<li class="wrapper-li"></li>').html(elHtml)[0]);
            idx++;
            continue;
        }

        newArr.push(el);
    }

    return newArr;
}
