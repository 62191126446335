import processArray from './processArray';

export default function ajaxPostsLoader() {
    var tM = window.themeModules;
    var tS = window.themeSettings;

    var AjaxPostsLoader = (function () {
        var ACTION_HEIGHT = 70,
            $document = $(document),
            $window = $(window);

        function AjaxPostsLoader(el) {
            this.el = el;
            this.$el = $(el);
            this.template = this.type = this.$el.data('ajax-posts-loader');

            if (this.template && tM.ajaxArgs && tM.ajaxArgs.hasOwnProperty(this.type)) {
                this.count = parseInt(this.$el.data('count'), 10);
                this.data = tM.ajaxArgs[this.type];
                this.handlers = {};
                this.isSlider = this.$el.hasClass('slides');
                this.addEventListeners();
                this.$el.data('ajax-posts-loader', this);
            }
        }

        AjaxPostsLoader.prototype = Object.create(null, {
            constructor: {
                value: AjaxPostsLoader
            },
            busy: {
                /**
                 * Check if object busy
                 * @returns {boolean}
                 */
                get: function () {
                    return !!this._isBusy;
                },
                /**
                 * Set object busy before AJAX
                 * @param isBusy
                 */
                set: function (isBusy) {

                    if (isBusy && !this._isBusy) {
                        this._isBusy = true;
                    } else if (!isBusy && this._isBusy) {
                        this._isBusy = false;
                    }
                }
            },
            data: {
                /**
                 * Get request params
                 * @returns {*}
                 */
                get: function () {
                    return this._data;
                },
                /**
                 * Set request params
                 * @param data
                 */
                set: function (data) {

                    try {
                        this._data = $.parseJSON(data);
                    } catch (e) {
                        this._data = data;
                    }
                    this._data.posts_per_page = parseInt(this._data.posts_per_page, 10);

                    if (!this._data.offset) {
                        this._data.offset = parseInt(this._data.posts_per_page, 10);
                    }
                    this._data.type = this.type;
                }
            },
            hold: {
                /**
                 * Check if object held (e.g. all posts already loaded)
                 * @returns {boolean}
                 */
                get: function () {
                    return !!this._isHold;
                },
                /**
                 * Hold object (e.g. when all posts already loaded)
                 * @param isHold
                 */
                set: function (isHold) {
                    this._isHold = isHold;

                    if (this._isHold && !this.isSlider) {
                        $window.off('scroll', this.handlers.onScroll);
                    }
                }
            },
            template: {
                /**
                 * Get WP template
                 * @returns {wp.template|null}
                 */
                get: function () {
                    return this._template;
                },
                /**
                 * Set WP template
                 * @param value
                 */
                set: function (value) {
                    value = 'preview-' + value;
                    this._template = document.getElementById('tmpl-' + value) ? wp.template(value) : null;
                }
            },
            addEventListeners: {
                /**
                 * Add all event handlers
                 * @returns {AjaxPostsLoader}
                 */
                value: function () {

                    if (!this.isSlider) {
                        this.handlers.onScroll = this.onScroll.bind(this);
                        $window.on('scroll', this.handlers.onScroll);
                    }

                    return this;
                }
            },
            onScroll: {
                /**
                 * Scroll page event handler
                 * @returns {AjaxPostsLoader}
                 */
                value: function () {

                    if (this.isEnd()) {
                        this.checkCount()
                            .load();
                    }

                    return this;
                }
            },
            isEnd: {
                /**
                 * Check if all user scrolled page to ACTION_HEIGHT
                 * @returns {boolean}
                 */
                value: function () {
                    return $window.scrollTop() >= ($document.height() - $window.height()) * ACTION_HEIGHT / 100;
                }
            },
            checkCount: {
                /**
                 * Check if all posts already loaded
                 * @returns {AjaxPostsLoader}
                 */
                value: function () {
                    this.hold = this.count <= this.data.offset;

                    return this;
                }
            },
            load: {
                /**
                 * Load more posts
                 * @returns {AjaxPostsLoader}
                 */
                value: function () {

                    if (!this.hold && !this.busy) {
                        this.busy = true;

                        wp.ajax.send(tS.actions.get_posts, {
                            data: this.data,
                            type: 'GET',
                            success: this.success.bind(this),
                            error: function () {
                                this.hold = true;
                            }.bind(this)
                        }).always(function (response) {
                            this.$el.trigger('postsload', [response]);
                            this.busy = false;
                        }.bind(this));
                    }

                    return this;
                }
            },
            success: {
                /**
                 * Posts loaded success handler
                 * @param response
                 * @returns {AjaxPostsLoader}
                 */
                value: function (response) {
                    var html = '',
                        $html;

                    if (Array.isArray(response) && response.length) {
                        response.forEach(function (data) {
                            html += this.template(data);
                        }.bind(this));
                        $html = $(html);
                        this._data.offset += this._data.posts_per_page;

                        if (this.$el.hasClass('post-list--litt-smarter')) {
                            var wrappers = this.$el.find('> li.wrapper-li').length;
                            var offset = wrappers + this.$el.find('> li').length;
                            $html = $(processArray($html.filter('li'), offset));
                        }

                        this.$el.append($html)
                            .trigger('postsadd', [$html]);
                        this.checkCount();
                    } else {
                        this.hold = true;
                    }

                    return this;
                }
            },
            toString: {
                /**
                 * Convert object to string
                 * @returns {string}
                 */
                value: function () {
                    return this.type;
                }
            },
            valueOf: {
                /**
                 * Get object
                 * @returns {AjaxPostsLoader}
                 */
                value: function () {
                    return this;
                }
            }
        });

        return AjaxPostsLoader;
    })();

    /**
     * Create jQuery plugin
     * @returns {*}
     */
    $.fn.ajaxPostsLoader = function () {
        return this.each(function () {
            new AjaxPostsLoader(this);
        });
    };
}
